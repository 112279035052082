import {
  React,
  _
} from "$Imports/Imports";

import {
  ITenantViewProps
} from "../TenantViewProps";

import {
  ISamsaraRairErrorReportFreezerServiceInjectedProps,
  SamsaraRairErrorReportService
} from "$State/job-views/SamsaraRairErrorReportFreezerService";

import {
  AjaxActionIndicator
} from "$Components/Common/AjaxActionIndicator";

import {
  Snackbar,
} from "@material-ui/core";

import {
  DataTable,
  IDataTableColumn,
} from "$Components/Common";

interface ISamsaraRairErrorReportViewBaseProps {

}

type ISamsaraRairErrorReportViewProps = ISamsaraRairErrorReportViewBaseProps
  & ITenantViewProps
  & ISamsaraRairErrorReportFreezerServiceInjectedProps;

interface IRairErrorRow {
  driverId: string;
  message: string;
}

class _SamsaraRairErrorReportView extends React.Component<ISamsaraRairErrorReportViewProps> {
  private readonly columns: IDataTableColumn<IRairErrorRow>[] = [
    {
      columnName: "driverId",
      columnFieldData: (x) => x.driverId ?? "",
      sortMethod: (x) => (x.driverId ?? "").toLowerCase(),
      headerValue: "Driver Id",
      headerProps: {}
    },
    {
      columnName: "message",
      columnFieldData: (x) => x.message ?? "",
      sortMethod: (x) => (x.message ?? "").toLowerCase(),
      headerValue: "Message",
      headerProps: {}
    }
  ]


  componentDidMount() {
    if (this.props.job !== null && this.props.job.id !== undefined) {
      this.props.samsaraRairErrorReport.setJobId(this.props.job.id);
      this.props.samsaraRairErrorReport.fetchRairErrors();
    }
  }

  render() {
    const {
      rairErrorResults
    } = this.props.samsaraRairErrorReport.getState();

    return (
      <>
        <AjaxActionIndicator state={[rairErrorResults]} />
        <DataTable
          stickyHeader
          columns={this.columns}
          data={rairErrorResults.data?.success && rairErrorResults.data?.data !== undefined ? (
            _.map(rairErrorResults.data?.data, (value, key) => (
              {
                driverId: key,
                message: value
              }
            ))
          ) : []}
          />

        <Snackbar
          open={rairErrorResults.hasFetched && !rairErrorResults.data?.success}
          message={"Error loading data from server"}
          autoHideDuration={5000}
          anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        />
      </>
    );
  }
}

export const SamsaraRairErrorReportView = SamsaraRairErrorReportService.inject(
  _SamsaraRairErrorReportView
);