import {
  React,
  bind,
  _,
  numeral,
  moment
} from "$Imports/Imports";

import {
  SearchBox,
  DateFormatter,
  timeZoneType,
  DataTablePager
} from "$Components/Common";

import {
  MultipleSmartDriveErrorSummaryGrid
} from "./MultipleSmartDriveErrorSummaryGrid"

import {
  IPagerState
} from "$State/PagerPagingState";

import {
  MultipleSmartDriveErrorReportService
} from "$State/job-views/MultipleSmartDriveErrorReportService";

import {
  SmartDriveExportResultListResponseBase,
  SmartDriveExportResult,
  SmartDriveExportError,
} from "$Generated/api";

import {
  IconButton,
  Divider
} from "$Imports/MaterialUIComponents";

import {
  ArrowDownward,
} from "$Imports/MaterialUIIcons";

import {
  TimeZoneService,
  ITimeZoneServiceInjectedProps
} from "$State/TimeZoneFreezerService";

const styles: {
  paperMargin: string;
  filterContainer: string;
  adapterContainer: string;
  dateRangeContainer: string;
  searchHeaderContainer: string;
  searchLabel: string;
  headerGroup: string;
} = require("../error-report-view/SubmissionErrorReport.scss");


interface ISubmissionErrorReportBaseProps {
  search: string;
  pagerState: IPagerState;
  data: SmartDriveExportResultListResponseBase;
}

type ISubmissionErrorReportProps = ISubmissionErrorReportBaseProps & ITimeZoneServiceInjectedProps;

class _MultipleSmartDriveErrorReport extends React.Component<ISubmissionErrorReportProps> {

  @bind
  private _onPagerStateChange(pagerState: IPagerState) {
    MultipleSmartDriveErrorReportService.setVehicleSubmissionPagerState(pagerState);
  }

  @bind
  private _onPagerStateChangePager(page: number, rowsPerPage: number) {
    this._onPagerStateChange({
      page,
      rowsPerPage,
    });
  }

  private _onSearch(data: SmartDriveExportError[], search: string): SmartDriveExportError[] {
    if (search.trim() === "") {
      return data;
    }

    return _.filter(data, (d) => {

      const employeeIdResults = (d.employeeId ? d.employeeId.toLowerCase() : "").indexOf(search.toLowerCase()) !== -1;

      if (employeeIdResults) {
        return true;
      }

      const driverIdResults = (d.responseCode ? d.responseCode.toLowerCase() : "").indexOf(search.toLowerCase()) !== -1;

      if (driverIdResults) {
        return true;
      }

      const vehicleIdResults = (d.responseMessage ? d.responseMessage.toLowerCase() : "").indexOf(search.toLowerCase()) !== -1;

      if (vehicleIdResults) {
        return true;
      }

      const vehicleSNResults = (d.vehicleSN ? d.vehicleSN.toLowerCase() : "").indexOf(search.toLowerCase()) !== -1;

      if (vehicleSNResults) {
        return true;
      }

      return false;
    });
  }

  @bind
  private _onClearSearch() {
    MultipleSmartDriveErrorReportService.setVehicleSubmissionErrorSearch("");
  }

  @bind
  private _onDebounceChange(newValue: string) {
    MultipleSmartDriveErrorReportService.setVehicleSubmissionErrorSearch(newValue);
  }

  @bind
  private saveCSV() {
    const { search, data } = this.props;

    const allResponses: SmartDriveExportResult[] = (data && data.data) ? data.data : [];

    const responseData: SmartDriveExportError[] = [];
    allResponses.forEach(response => {
      if (response.exportErrors !== undefined) {
        response.exportErrors.forEach(exportError => responseData.push(exportError))
      }
    });

    const filterData = this._onSearch(responseData, search);
    const timeZone: timeZoneType = this.props.timeZoneService.getTimeZone() || "UTC";
    const isMilitaryTime: boolean = this.props.timeZoneService.getisMilitaryTime() || false;

    let csvData = "Employee Id, Vehicle SN, Start Date, End Date, Response Code, Response Message\n";

    filterData.forEach(row => {
      csvData +=
        this._createCsvColumnValue(row.employeeId) +
        this._createCsvColumnValue(row.vehicleSN) +
        (row.startDateTime ? DateFormatter.formatDateString(row.startDateTime, timeZone, "", isMilitaryTime) : "") + "," +
        ((row.endDateTime && !moment(row.endDateTime).isSame("0001-01-01T00:00:00")) ? DateFormatter.formatDateString(row.endDateTime, timeZone, "", isMilitaryTime) : "") + "," +
        this._createCsvColumnValue(row.responseCode) +
        this._createCsvColumnValue(row.responseMessage, true);
    });

    const saveDocument = document.createElement('a');
    saveDocument.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvData);
    saveDocument.target = '_blank';
    saveDocument.download = 'submissionErrors-bulk' + moment().format() + '.csv';
    saveDocument.click();
  }

  private _createCsvColumnValue(value?: string, lastColumn?: boolean): string {
    return (!value ? "" : encodeURIComponent(value)) + (lastColumn ? "\n" : ",");
  }

  @bind
  private _onChangePage() {
    // Do nothing.  Handled by the component.  Remove once typescript has been updated.
  }

  render() {
    const { search, pagerState, data } = this.props;

    const allResponses: SmartDriveExportResult[] = (data && data.data) ? data.data : [];

    const responseData: SmartDriveExportError[] = [];
    allResponses.forEach(response => {
      if (response.exportErrors !== undefined) {
        response.exportErrors.forEach(exportError => responseData.push(exportError))
      }
    });

    const filterData = this._onSearch(responseData, search);

    return (
      <>
        <Divider />
        <div className={styles.searchHeaderContainer}>
          <div className={styles.headerGroup}>
            <div>
              <SearchBox
                value={this.props.search}
                label=" "
                filterStatusLabelClassName={styles.searchLabel}
                onDebouncedChange={this._onDebounceChange}
                filterStatusLabel={`${numeral(filterData.length).format("0,000")} ${filterData.length === 1 ? "Error" : "Errors"}`}
                onClearClick={this._onClearSearch}
              />
            </div>
            <div style={{ paddingLeft: "1em" }}>
            </div>
          </div>
          <div className={styles.headerGroup}>
            <div>
              <IconButton
                color="primary"
                title="Download"
                onClick={this.saveCSV}
              >
                <ArrowDownward />
              </IconButton>
            </div>
            {filterData.length > 0 ? <table>
              <tr>
                <DataTablePager
                  style={{ borderBottom: "none" }}
                  onChangePage={this._onChangePage}
                  count={filterData.length}
                  page={pagerState.page}
                  rowsPerPage={pagerState.rowsPerPage}
                  onPagerStateChange={this._onPagerStateChangePager}
                />
              </tr>
            </table> : null
            }
          </div>
        </div>
        {
          <>
            <Divider />
            <MultipleSmartDriveErrorSummaryGrid
              data={filterData}
              pager={pagerState}
              onPagerStateChange={this._onPagerStateChange}
              submissionStatus={data.success ? "Complete" : "Failed"}
            />
          </>
        }
      </>
    );
  }
}

export const MultipleSmartDriveErrorReport = TimeZoneService.inject(
  _MultipleSmartDriveErrorReport
);