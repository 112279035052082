import {
  FreezerService,
  IAjaxState,
  managedAjaxUtil,
  _
} from "$Imports/Imports";


import { 
  ConfigurationApiFactory,
  StringListResponseBase,
  VehicleCountReportViewModelResponseBase
} from "$Generated/api";

import {
  IPagerState
} from "./PagerPagingState";

import {
  SitePubSubManager
} from "$Utilities/PubSubUtil";

const InjectPropName = "vehicleCountService";

export interface IVehicleCountFile {
  filename: string;
  display: string;
}

interface IVehicleCountState {
  fileListResults: IAjaxState<StringListResponseBase>;
  fileOptions: IVehicleCountFile[];
  selectedReport: string;
  vehicleCountReportResults: IAjaxState<VehicleCountReportViewModelResponseBase>;
  filterText: string;
  vehicleCountPager: IPagerState;
}

const defaultPagerSettings: IPagerState = {
  page: 0,
  rowsPerPage: 100
};

const initialState = {
  fileListResults: managedAjaxUtil.createInitialState(),
  fileOptions: [],
  selectedReport: "",
  vehicleCountReportResults: managedAjaxUtil.createInitialState(),
  filterText: "",
  vehicleCountPager: defaultPagerSettings
} as IVehicleCountState;

class VehicleCountFreezerService extends FreezerService<IVehicleCountState, typeof InjectPropName> {
  constructor() {
    super(initialState, InjectPropName);

    SitePubSubManager.subscribe("application:logout", this.clearFreezer);
  }

  private clearFreezer() {
    this.freezer.get().set(initialState);
  }

  public async fetchFileList(forceUpdate: boolean = false) {
    const results = this.freezer.get().fileListResults;

    if (results.hasFetched && !forceUpdate) {
      return;
    }

    return managedAjaxUtil.fetchResults({
      ajaxStateProperty: "fileListResults",
      freezer: this.freezer,
      params: {
        tenantId: "VehicleCountReporting",
        prefix: ""
      },
      onExecute: (apiOptions, params, options) => {
        const apiFactory = ConfigurationApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return apiFactory.apiV1ConfigurationFilelistTenantIdPrefixGet(params);
      },
      onOk: (data: StringListResponseBase) => {
        if (data.success && data.data) {
          let vCountFiles = _.filter(data.data, x => x.indexOf("vcount.csv") > -1)
            .map(x => x.slice(34));
          vCountFiles.sort();
          vCountFiles = vCountFiles.reverse();

          let fileOptions = vCountFiles.map(x => {
            let datestamp = x.substring(0, 8);
            console.log(datestamp);
            return {
              filename: x,
              display: datestamp.slice(0, 4) + "-" + datestamp.slice(4, 6) + "-" + datestamp.slice(6)
            } as IVehicleCountFile
          });

          this.freezer.get().set({
            fileOptions,
            selectedReport: vCountFiles[0] // default to most recent
          });

          this.fetchReport(true);
        }
      }
    });
  }

  public async fetchReport(forceUpdate: boolean = false) {
    const results = this.freezer.get().vehicleCountReportResults;

    if (results.hasFetched && !forceUpdate) {
      return;
    }

    const filename = this.freezer.get().selectedReport;

    return managedAjaxUtil.fetchResults({
      ajaxStateProperty: "vehicleCountReportResults",
      freezer: this.freezer,
      params: {
        filename
      },
      onExecute: (apiOptions, params, options) => {
        const apiFactory = ConfigurationApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return apiFactory.apiV1ConfigurationVehicleCountReportFilenameGet(params);
      }
    })
  }

  public setSelectedReport(selectedReport: string) {
    this.freezer.get().set({ selectedReport });
    this.fetchReport(true);
  }

  public setFilterText(filterText: string) {
    this.freezer.get().set({ filterText });
  }

  public setPagerState(pager: IPagerState) {
    this.freezer.get().vehicleCountPager.set(pager);
  }
}

export const VehicleCountService = new VehicleCountFreezerService();
export type IVehicleCountServiceInjectedProps = ReturnType<VehicleCountFreezerService["getPropsForInjection"]>;
