import {
  IPagerState
} from "./PagerPagingState";

import {
  ISortState
} from "./SortState";

import {
  FreezerService,
  IAjaxState,
  managedAjaxUtil,
  _,
  bind
} from "$Imports/Imports";

import {
  TenantSummaryViewModelIEnumerableResponseBase,
  TenantApiFactory,
  TenantViewModel,
  TenantSummaryViewModel,
  TenantSuccessSummaryViewModelIEnumerableResponseBase,
  ResponseBase,
  TenantTokenExpirationDateViewModelIEnumerableResponseBase,
} from "$Generated/api";

import {
  SitePubSubManager
} from "$Utilities/PubSubUtil";

import {
  ErrorService
} from "./ErrorFreezerService";

import {
  AuthenticationSettingsInstance
} from "$Utilities/Security";

const InjectedPropName = "tenantSummaryService";

export type viewType = "Add" | "Edit" | "Rename" | "None" | "Activate" | "Deactivate" | "AdvancedSettings" | "HardDeleteTenant";

interface IFilter {
  search: string;
  adapter: string;
}

interface ITenantSummaryState {
  tenantResults: IAjaxState<TenantSummaryViewModelIEnumerableResponseBase>;
  tenantSummaryResults: IAjaxState<TenantSuccessSummaryViewModelIEnumerableResponseBase>;
  tokenExpDateResults: IAjaxState<TenantTokenExpirationDateViewModelIEnumerableResponseBase>;
  tenantResultPagedState: IPagerState;
  filter: IFilter;
  tenantSortState: ISortState;

  /** Which additional view to display, or "None" */
  view: viewType;

  /** If true, additionally display inactive tenants in the tenant summary */
  showInactiveTenants: boolean;

  /** The tenant the user wants to edit/add */
  editAddTenant: TenantSummaryViewModel | null;

  /** Activation ajax result stored in this property */
  activateTenantResult: IAjaxState<ResponseBase>;

  /** The tenant that the user wants to activate */
  activateTenant: TenantSummaryViewModel | null;

  /** Deactivation ajax result stored in this property */
  deactivateTenantResult: IAjaxState<ResponseBase>;

  /** The tenant that the user wants to deactivate */
  deactivateTenant: TenantSummaryViewModel | null;

  /** Hard Delete ajax result stored in this property */
  hardDeleteTenantResult: IAjaxState<ResponseBase>;

  /** The tenant that the user wants to hard delete */
  hardDeleteTenant: string | null;
}

const defaultPagerState: IPagerState = {
  page: 0,
  rowsPerPage: 100,
};

class TenantSummaryFreezerService extends FreezerService<ITenantSummaryState, typeof InjectedPropName> {
  constructor() {
    super({
      tenantResults: managedAjaxUtil.createInitialState(),
      tenantSummaryResults: managedAjaxUtil.createInitialState(),
      tokenExpDateResults: managedAjaxUtil.createInitialState(),
      tenantResultPagedState: defaultPagerState,
      filter: {
        search: "",
        adapter: "",
      },
      view: "None",
      editAddTenant: null,
      tenantSortState: {
        sortColumnName: "tenant-friendly-name",
        sortDirection: "asc"
      },
      showInactiveTenants: false,
      activateTenantResult: managedAjaxUtil.createInitialState(),
      activateTenant: null,
      deactivateTenantResult: managedAjaxUtil.createInitialState(),
      deactivateTenant: null,
      hardDeleteTenantResult: managedAjaxUtil.createInitialState(),
      hardDeleteTenant: null
    }, InjectedPropName);

    SitePubSubManager.subscribe("application:login:before", this.clearResults);
    SitePubSubManager.subscribe("tenant:tenant-created", () => this.fetchTenants(true));
    SitePubSubManager.subscribe("tenant:tenant-deactivated", () => this.fetchTenants(true));
    SitePubSubManager.subscribe("tenant:tenant-hard-delete", () => this.fetchTenants(true));
    SitePubSubManager.subscribe("tenant:tenant-activated", () => this.fetchTenants(true));
    SitePubSubManager.subscribe("tenant:tenant-renamed", () => this.fetchTenants(true));
    SitePubSubManager.subscribe("tenant:advanced-settings-saved", () => this.fetchTenants(true));
  }

  //#region Search

  public setFilterValue(filter: Partial<IFilter>) {
    const filterObject = this.freezer.get().filter.toJS();
    const pager = this.freezer.get().tenantResultPagedState.toJS();

    _.assign(filterObject, filter);

    this.freezer.get().set({
      filter: filterObject,
      tenantResultPagedState: _.assign(pager, { page: 0 }),
    });
  }

  //#endregion

  //#region Show Inactive Tenants

  public setShowInactiveTenants(showInactive: boolean){
    this.freezer.get().set({
      showInactiveTenants: showInactive,
    })
  }

  //#endregion

  //#region Tenant Summary

  public setTenantSort(sortState: Partial<ISortState>) {
    this.freezer.get().tenantSortState.set(sortState);
  }

  public setTenantPagerState(pager: IPagerState) {
    this.freezer.get().tenantResultPagedState.set(pager);
  }

  //#endregion Tenant Summary

  //#region Edit Tenant

  public setEditTenant(tenant: TenantViewModel) {
    this.freezer.get().set({
      view: "Edit",
      editAddTenant: _.clone(tenant),
    });
  }

  public closeEditAddDialog() {
    this.freezer.get().set({
      view: "None",
      editAddTenant: null
    });
  }

  //#endregion

  //#region Activate Tenant

  public closeActivateDialog() {
    this.freezer.get().set({
      view: "None",
      activateTenant: null,
    })
  }

  public setActivateTenant(tenant: TenantViewModel) {
    this.freezer.get().set({
      view: "Activate",
      activateTenant: _.clone(tenant),
    })
  }

  public async activateTenant() {
    const failMessage = "Failed to activate tenant";
    return managedAjaxUtil.fetchResults({
        freezer: this.freezer,
        ajaxStateProperty: "activateTenantResult",
        onExecute: (apiOptions, params, options) => {
          const tenant = this.freezer.get().activateTenant;

          const tenantViewModel: TenantViewModel = {
            tenantId: tenant?.workflowInstanceId,
          }
          const tenantApi = TenantApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
          return tenantApi.apiV1TenantActivatePost({ body: tenantViewModel });
        },
        onOk: (data: ResponseBase) => {
          if (data.success) {
            SitePubSubManager.publish("tenant:tenant-activated", data.success);
          }
          else {
            ErrorService.pushErrorMessage(failMessage);
          }

          return data;
        },
        onError: () => {
          ErrorService.pushErrorMessage(failMessage);
        }
    });
  }

  //#endregion

  //#region Deactivate Tenant

  public closeDeactivateDialog() {
    this.freezer.get().set({
      view: "None",
      deactivateTenant: null, 
    });
  }

  public setDeactivateTenant(tenant: TenantViewModel) {
    this.freezer.get().set({
      view: "Deactivate",
      deactivateTenant: _.clone(tenant),
    });
  }

  public async deactivateTenant() {
    const failMessage = "Failed to deactivate tenant";
    return managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "deactivateTenantResult",
      onExecute: (apiOptions, params, options) => {
        const tenant = this.freezer.get().deactivateTenant;

        const tenantViewModel: TenantViewModel = {
          tenantId: tenant?.workflowInstanceId,
        }
        const tenantApi = TenantApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return tenantApi.apiV1TenantDeletePost({ body: tenantViewModel });
      },
      onOk: (data: ResponseBase) => {
        if (data.success) {
          SitePubSubManager.publish("tenant:tenant-deactivated", data.success);
        }
        else {
          ErrorService.pushErrorMessage(failMessage);
        }

        return data;
      },
      onError: () => {
        ErrorService.pushErrorMessage(failMessage);
      }
    });
  }
  //#endregion

  //#region Hard Delete Tenant
  public closeHardDeleteTenantDialog() {
    this.freezer.get().set({
      view: "None",
      hardDeleteTenant: null, 
    });
  }

  public setHardDeleteTenant(tenantId: string) {
    this.freezer.get().set({
      view: "HardDeleteTenant",
      hardDeleteTenant: tenantId,
    });
  }

  public async hardDeleteTenant() {
    const failMessage = "Failed to hard delete tenant";
    return managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "hardDeleteTenantResult",
      onExecute: (apiOptions, params, options) => {
        const tenantId = this.freezer.get().hardDeleteTenant;

        const tenantViewModel: TenantViewModel = {
          tenantId: tenantId || "",
        }
        const tenantApi = TenantApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return tenantApi.apiV1TenantHarddeletePost({ body: tenantViewModel });
      },
      onOk: (data: ResponseBase) => {
        if (data.success) {
          SitePubSubManager.publish("tenant:tenant-hard-delete", data.success);
        }
        else {
          ErrorService.pushErrorMessage(failMessage);
        }

        return data;
      },
      onError: () => {
        ErrorService.pushErrorMessage(failMessage);
      }
    });
  }

  //#endregion

  public async fetchTenants(forceUpdate: boolean = false) {
    const adapterSummaryState = this.freezer.get().tenantResults;

    if (adapterSummaryState.hasFetched && !forceUpdate) {
      return;
    }

    return managedAjaxUtil.fetchResults({
      ajaxStateProperty: "tenantResults",
      freezer: this.freezer,
      onExecute: (apiOptions, params, options) => {
        const tenantFactory = TenantApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        this.fetchTenantSummaries(true)
        this.fetchTokenExpDates(true);
        return tenantFactory.apiV1TenantSummaryGet({
          isActive: !this.freezer.get().showInactiveTenants,
        });
      },
      onError: () => {
        ErrorService.pushErrorMessage("Failed to retrieve tenant data from the server.");
      }
    });
  }

  public async fetchTenantSummaries(forceUpdate: boolean = false) {
    const adapterSummaryState = this.freezer.get().tenantSummaryResults;

    if (adapterSummaryState.hasFetched && !forceUpdate) {
      return;
    }

    return managedAjaxUtil.fetchResults({
      ajaxStateProperty: "tenantSummaryResults",
      freezer: this.freezer,
      onExecute: (apiOptions, params, options) => {
        const tenantFactory = TenantApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        var summaries = tenantFactory.apiV1TenantSuccessSummaryGet({
          isActive: !this.freezer.get().showInactiveTenants,
        });
        return summaries;
      },
      onError: () => {
        ErrorService.pushErrorMessage("Failed to retrieve tenant success data from the server.");
      }
    });
  }

  public async fetchTokenExpDates(forceUpdate: boolean = false) {

    //SmartDrive uses 12, this checks if the user is a SmartDrive user.
    if(!AuthenticationSettingsInstance.isAdminUser()&&
      !(AuthenticationSettingsInstance.getClientIds().indexOf(12)!==-1)){
      return;
    }

    const tokenExpDataState = this.freezer.get().tokenExpDateResults;

    if (tokenExpDataState.hasFetched && !forceUpdate) {
      return;
    }
    
    return managedAjaxUtil.fetchResults({
      ajaxStateProperty: "tokenExpDateResults",
      freezer: this.freezer,
      onExecute: (apiOptions, params, options) => {
        const tenantFactory = TenantApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        var summaries = tenantFactory.apiV1TokenExpirationDatesGet({
          isActive: !this.freezer.get().showInactiveTenants,
        });
        return summaries;
      },
      onError: () => {
        ErrorService.pushErrorMessage("Failed to retrieve token expiration data from the server.");
      }
    });
  }

  @bind
  private clearResults() {
    this.freezer.get().set({
      tenantResults: managedAjaxUtil.createInitialState(),
      filter: {
        search: "",
        adapter: "",
      },
      tenantSortState: {
        sortColumnName: "tenant-friendly-name",
        sortDirection: "asc"
      }
    });

    this.freezer.get().tenantResultPagedState.set({
      page: 0
    });
  }
}

export const TenantSummaryService = new TenantSummaryFreezerService();
export type ITenantSummaryServiceInjectedProps = ReturnType<TenantSummaryFreezerService["getPropsForInjection"]>;