import {
  React,
  bind,
  moment,
  _
} from "$Imports/Imports";

import {
  TableRow
} from "$Imports/MaterialUIComponents";

import {
  DataTable,
  DataTablePager,
  IDataTableColumn,
  DateFormatter
} from "$Components/Common";

import {
  VehicleAssignmentViewModel
} from "$Generated/api";

import {
  IPagerState,
  calcPagedState
} from "$State/PagerPagingState";

interface IVehicleAssignmentGridProps {
  pagerState: IPagerState;
  data: VehicleAssignmentViewModel[];
  onPagerStateChange?: (pagerState: IPagerState) => void;
}

const styles: {
  columnHeader: string;
} = require("./VehicleAssignment.scss");

export class VehicleAssignmentGrid extends React.PureComponent<IVehicleAssignmentGridProps> {
  private readonly columns: IDataTableColumn<VehicleAssignmentViewModel>[] = [
    {
      columnName: "vehicle-id",
      columnFieldData: "vehicleId",
      sortMethod: (d) => (d.vehicleId ? d.vehicleId : "").toLowerCase(),
      headerValue: "Vehicle Id",
      headerProps: { className: styles.columnHeader }
    },
    {
      columnName: "driver-id",
      columnFieldData: "driverId",
      sortMethod: (d) => (d.driverId ? d.driverId : "").toLowerCase(),
      headerValue: "Driver Id",
      headerProps: { className: styles.columnHeader }
    },
    {
      columnName: "first-name",
      columnFieldData: "firstName",
      sortMethod: (d) => (d.firstName ? d.firstName : "").toLowerCase(),
      headerValue: "First Name",
      headerProps: { className: styles.columnHeader }
    },
    {
      columnName: "last-name",
      columnFieldData: "lastName",
      sortMethod: (d) => (d.lastName ? d.lastName : "").toLowerCase(),
      headerValue: "Last Name",
      headerProps: { className: styles.columnHeader }
    },
    {
      columnName: "start-date",
      columnFieldData: (d) => d.startDate ? (<DateFormatter value={d.startDate} />) : null,
      sortMethod: (d) => d.startDate,
      headerValue: "Start Date",
      headerProps: { className: styles.columnHeader }
    },
    {
      columnName: "end-date",
      columnFieldData: (d) => d.endDate && !moment(d.endDate).isSame("0001-01-01T00:00:00") ? (<DateFormatter value={d.endDate} />) : null,
      sortMethod: (d) => d.endDate,
      headerValue: "End Date",
      headerProps: { className: styles.columnHeader }
    }
  ];

  @bind
  private _onPagerStateChanged(page: number, rowsPerPage: number) {
    if (this.props.onPagerStateChange) {
      this.props.onPagerStateChange({
        page,
        rowsPerPage,
      });
    }
  }

  @bind
  private _onChangePage() {
    // Do nothing.  Handled by the component.  Remove once typescript has been updated.
  }

  render() {
    const { data, pagerState } = this.props;
    const pageState = calcPagedState(pagerState);

    return (
      <DataTable
        columns={this.columns}
        data={data}
        skipRows={pageState.skip}
        takeRows={pageState.take}
        stickyHeader
        tableFooterComponent={(
          <TableRow>
            <DataTablePager
              onChangePage={this._onChangePage}
              count={data.length}
              page={pagerState.page}
              rowsPerPage={pagerState.rowsPerPage}
              onPagerStateChange={this._onPagerStateChanged}
            />
          </TableRow>
        )}
      />
    );
  }
}
