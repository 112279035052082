import {
  React,
  _,
  memoizeOne,
  bind,
  React_Select
} from "$Imports/Imports";

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectProps,
  Input,
  TextField
} from "$Imports/MaterialUIComponents";

import {
  TenantSummaryViewModel
} from "$Generated/api";

import {
  joinClasses
} from "$Utilities/css";
import { size } from "lodash";

interface ITenantSelectorProps extends SelectProps {
  formControlClassName?: string;
  htmlForName?: string;
  sourceTenantData: TenantSummaryViewModel[];
  onTenantChange?: (tenant: TenantSummaryViewModel | undefined) => void;
  errorState: boolean;
}

const styles: {
  tenantSelector: string;
} = require("./TenantSelector.scss");

export class TenantSelector extends React.PureComponent<ITenantSelectorProps> {
  static defaultProps: Partial<ITenantSelectorProps> = {
    htmlForName: "tenant",
    input: (<Input name="tenant-name" id="tenant" />),
    fullWidth: true
  };

  private _sortName(tenants: TenantSummaryViewModel[]): TenantSummaryViewModel[] {
    return _.sortBy(tenants, (t) => {
      return t.tenantFriendlyName === undefined || t.tenantFriendlyName === null ? null : t.tenantFriendlyName.toLowerCase();
    });
  }

  private _findSelected(tenant: TenantSummaryViewModel[], searchValue: unknown): TenantSummaryViewModel | undefined {
    return _.find(tenant, (t) => {
      if (searchValue === undefined || searchValue === null) {
        return false;
      }

      return t.tenantId === searchValue || t.workflowInstanceId === searchValue || t.tenantFriendlyName === searchValue;
    });
  }

  private readonly _sortName_memoize = memoizeOne(this._sortName);
  private readonly _findSelected_memoize = memoizeOne(this._findSelected);

  @bind
  private _onChange(selectedOption: any, action: any): void {
    const { sourceTenantData } = this.props;
    const tenant = this._findSelected(sourceTenantData, selectedOption.value);
    if (this.props.onTenantChange) {
      this.props.onTenantChange(tenant);
    }
  }

  render() {
    const { htmlForName, formControlClassName, sourceTenantData, value, onChange, onTenantChange, errorState, ...passThroughProps } = this.props;

    const sortedValue = this._sortName_memoize(sourceTenantData);
    const selectedValue = this._findSelected_memoize(sourceTenantData, value);

    const tenantOptions = sortedValue.map(tenant => {
      if (typeof tenant.tenantId == "string" && typeof tenant.tenantFriendlyName == "string") {
        return ({ value: tenant.tenantId, label: tenant.tenantFriendlyName })
      }
    })
    const tenantSelection = tenantOptions.filter(function (option) {
      if (option) {
        return option.value === selectedValue?.tenantId;
      } else {
        "";
      }
    });
    const customStyles = {
      option: (provided: any, state: { isSelected: any; }) => ({
        ...provided,
        color: state.isSelected ? 'black' : 'black',
        backgroundColor: state.isSelected ? '#E1E1E1' : 'white'
      }),
      control: (provided: any, state: any) => ({
        ...provided,
        marginTop: "0%",
        width: 300,
        boxShadow: state.isFocused ? "0 0 0 2px #202021" : "0",
        background: '#FAFBFC',
        size: 12,
        border: 'none'
      }),
      menu: (provided: any, state: any) => ({
        ...provided,
        zIndex: 3
      })
    }

    return (
      <div>
        <InputLabel style={{ marginBottom: 0, fontSize: 12 }} htmlFor="tenant">Tenant*</InputLabel>
        <FormControl
          className={joinClasses([styles.tenantSelector, formControlClassName])}
        >
          <React_Select
            className={errorState && !selectedValue ? "danger" : "standard"}
            autoFocus
            options={tenantOptions}
            styles={customStyles}
            placeholder="Select a Tenant"
            value={tenantSelection}
            components={{
              IndicatorSeparator: () => null
            }}
            onChange={this._onChange}
          />
        </FormControl>
      </div>
    );
  }
}
