import {
  moment,
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  joinClasses
} from "$Utilities/css";

import {
  calcDateRange,
  calcRangeType,
  IDateRange,
  rangeType
} from "./dateRangeCalculator";

import {
  FormControl,
  Select,
  MenuItem,
  InputLabel
} from "$Imports/MaterialUIComponents";

import {
  DateRangeIcon
} from '$Imports/MaterialUIIcons';

import {
  AssignmentRangeOptions
} from "./DateRangeFilterOptions";

import DayPickerInput from "react-day-picker/DayPickerInput";
import {
  DayModifiers
} from "react-day-picker/types/Modifiers";

import './DayPickerStyles.css';


const styles: {
  mainContainer: string;
  items: string;
  DayPickerOverlayWrapper: string;
  StartDayPickerOverlay: string;
  EndDayPickerOverlay: string;
  DayPickerInputBordered: string;
  DateRangeElement: string;
} = require("./DateRangeFilter.scss");

interface IDateSelectorItem {
  value: rangeType;
  label: string;
}

interface IDateRangeFilterProps {
  value?: IDateRange | null;
  rangeOptions?: rangeType[];
  onChange?: (dateRange: IDateRange) => void;
  containerClassName?: string;
  maxDayRange?: number;
  minDate?: Date | moment.Moment;
  maxDate?: Date | moment.Moment;
  singleDateMode: boolean;
}

interface IDateRangeFilterState {
  displayMonth: Date;
  lastSavedValue: IDateRange | null;
  currentValue: IDateRange | null;
}

function calcDisplayMonth(dateValue?: IDateRange | null): Date {
  const dateRange = dateValue ? dateValue : {
    endDate: null,
    startDate: null
  };

  const startDate: Date = (dateRange.startDate instanceof Date) ? dateRange.startDate : (moment.isMoment(dateRange.startDate) ? dateRange.startDate.toDate() : new Date()); // react-day-picker uses Date only.
  const displayMonth = new Date(startDate.toDateString());
  const monthNum = displayMonth.getMonth();
  if (monthNum === moment().month()) {
    if (monthNum === 0) {
      displayMonth.setMonth(11);
      displayMonth.setFullYear(displayMonth.getFullYear() - 1);
    }
    else {
      displayMonth.setMonth(monthNum - 1);
    }
  }

  return displayMonth;
}


const menuItemList: IDateSelectorItem[] = [
  {
    value: "today",
    label: "Today"
  },
  {
    value: "yesterday",
    label: "Yesterday"
  },
  {
    value: "last-7-days",
    label: "Last 7 Days"
  },
  {
    value: "last-14-days",
    label: "Last 14 Days"
  },
  {
    value: "last-30-days",
    label: "Last 30 Days"
  },
  {
    value: "custom",
    label: "Custom"
  }
];

export class DateRangeFilter extends React.PureComponent<IDateRangeFilterProps, IDateRangeFilterState> {

  static defaultProps: Partial<IDateRangeFilterProps> = {
    rangeOptions: AssignmentRangeOptions,
    maxDayRange: 0,
    singleDateMode: false
  };

  // The function updates state if the value property changes.
  static getDerivedStateFromProps(props: IDateRangeFilterProps, state: IDateRangeFilterState): Partial<IDateRangeFilterState> | null {
    if (state === null || props.value !== state.lastSavedValue) {
      return {
        displayMonth: calcDisplayMonth(props.value),
        lastSavedValue: props.value,
        currentValue: props.value
      };
    }

    return null;
  }


  private endDate?: DayPickerInput | null;
  private startDateSet: boolean = false;


  private _adjustDateRange(range: IDateRange, dateChanged: "start-date" | "end-date"): IDateRange {
    const { maxDayRange, value, maxDate, minDate } = this.props;

    if (range.endDate === null ||
      range.startDate === null ||
      !moment(range.endDate).isValid() ||
      !moment(range.startDate).isValid()) {
      return range;
    }

    let currentRange: number = 0;

    if (value && value.endDate && value.startDate) {
      currentRange = moment(value.endDate).diff(moment(value.startDate), "days");
    }

    // Corrects the range if the dates if the start date is after the end date.
    // If this occurs the range is adjusted based on the number of day from the prior range.
    if (moment(range.startDate).isAfter(moment(range.endDate))) {
      if (currentRange <= 0) {
        if (dateChanged === "start-date") {
          range.endDate = moment(range.startDate).endOf("day");
        }
        if (dateChanged === "end-date") {
          range.startDate = moment(range.endDate).startOf("day");
        }
      }

      if (currentRange > 0) {
        if (dateChanged === "start-date") {
          range.endDate = moment(range.startDate).add(currentRange, "days").endOf("day");
        }
        if (dateChanged === "end-date") {
          range.startDate = moment(range.endDate).subtract(currentRange, "days").startOf("day");
        }
      }
    }

    if (maxDate && moment(range.endDate).isAfter(moment(maxDate))) {
      range.endDate = moment(maxDate).endOf("day");
    }

    if (minDate && moment(range.startDate).isBefore(moment(minDate))) {
      range.startDate = moment(minDate).startOf("day");
    }

    const newRangeDays = moment(range.endDate).diff(moment(range.startDate), "days");

    if (maxDayRange !== undefined && maxDayRange > 0 && newRangeDays > maxDayRange) {
      range.endDate = moment(range.startDate).add(maxDayRange, "days").endOf("day");
    }

    return range;
  }

  @bind
  private _onEndDateChange(date: Date, dayModifiers: DayModifiers, input: DayPickerInput): void {

    if (this.startDateSet || this.props.singleDateMode) // we are now editing the start date (or both if single date mode)
    {
      const dateValue = this.state.currentValue ? this.state.currentValue : {
        startDate: null,
        endDate: null
      };

      const results: IDateRange = {
        endDate: null,
        startDate: null,
      };

      if (date) {
        results.endDate = moment(date).endOf("day");
        results.startDate = this.props.singleDateMode ? moment(date).startOf("day") : dateValue.startDate;
      } else {
        results.endDate = null;
        results.startDate = dateValue.startDate;
      }

      this._adjustDateRange(results, "end-date");
      this._raiseChangeEvent(results);
      this.startDateSet = false;
      if (this.endDate) {
        this.endDate.hideDayPicker();
        this.endDate.getInput().blur();
      }
    }
    else {
      this._onStartDateChange(date, dayModifiers, input);
      if (this.endDate) {
        this.endDate.getInput().focus();
      }
    }
  }

  @bind
  private _onStartDateChange(date: Date, dayModifiers: DayModifiers, input: DayPickerInput): void {
    const dateValue = this.state.currentValue ? this.state.currentValue : {
      startDate: null,
      endDate: null
    };

    const results: IDateRange = {
      endDate: null,
      startDate: null,
    };

    if (date) {
      results.endDate = dateValue.endDate;
      results.startDate = moment(date).startOf("day");
    } else {
      results.endDate = dateValue.endDate;
      results.startDate = null;
    }

    this._adjustDateRange(results, "start-date");
    this.setState({ currentValue: results });
    this._raiseChangeEvent(results);
    this.startDateSet = true;
  }

  private _raiseChangeEvent(dateRange: IDateRange): void {
    if (this.props.onChange) {
      this.props.onChange(dateRange);
    }
  }

  @bind
  private _onChange(event: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>, child: React.ReactNode): void {
    const type = event.target.value as rangeType;

    if (type !== "custom") {
      const dateRange = calcDateRange(type);
      this._raiseChangeEvent(dateRange);
    }
  }

  private _filterMenuOptionItem(items: IDateSelectorItem[], options: rangeType[]): IDateSelectorItem[] {
    return _.filter(items, (i) => {
      return _.indexOf(options, i.value) !== -1;
    });
  }


  private _formatDate(date: Date): string {
    return moment(date).format("MM/DD/YYYY");
  }

  render() {
    const { containerClassName, rangeOptions, maxDate, minDate, maxDayRange } = this.props;
    const { currentValue } = this.state;

    const dateRange = currentValue ? currentValue : {
      endDate: null,
      startDate: null
    };

    const range = calcRangeType(dateRange);

    let options: rangeType[] = [];
    if (rangeOptions) {
      options = options.concat(rangeOptions);
    }

    if (range === "custom") {
      options.push("custom");
    }

    const filter = this._filterMenuOptionItem(menuItemList, options);

    const startDate: Date = (dateRange.startDate instanceof Date) ? dateRange.startDate : (moment.isMoment(dateRange.startDate) ? dateRange.startDate.toDate() : new Date()); // react-day-picker uses Date only.
    const endDate: Date = (dateRange.endDate instanceof Date) ? dateRange.endDate : (moment.isMoment(dateRange.endDate) ? dateRange.endDate.toDate() : new Date()); // react-day-picker uses Date only.
    const pickerMaxDate: Date = (maxDate instanceof Date) ? maxDate : (moment.isMoment(maxDate) ? maxDate.toDate() : new Date()); // react-day-picker uses Date only.
    const pickerMinDate: Date = (minDate instanceof Date) ? minDate : (moment.isMoment(minDate) ? minDate.toDate() : new Date()); // react-day-picker uses Date only.
    const endLimiterDate: Date = (dateRange.startDate instanceof Date) ? dateRange.startDate : (moment.isMoment(dateRange.startDate) ? dateRange.startDate.toDate() : new Date()); // react-day-picker uses Date only.
    endLimiterDate.setDate(endLimiterDate.getDate() + (maxDayRange || 30));

    const modifiers = { start: startDate, end: endDate };

    return (
      <div
        className={joinClasses([styles.mainContainer, containerClassName])}
      >
        <div style={{ marginLeft: "1.125em", width: "12em" }}>
          <FormControl
            fullWidth={true}
          >
            {(this.props.singleDateMode ?
              <InputLabel htmlFor="date-range">One Day</InputLabel> :
              <InputLabel htmlFor="date-range">Date Range</InputLabel>
            )}
            <Select
              value={range}
              onChange={this._onChange}
              fullWidth={true}
            >
              {
                _.map(filter, (f, fIdx) => (<MenuItem key={fIdx} value={f.value}>{f.label}</MenuItem>))
              }
            </Select>
          </FormControl>
        </div>
        {(
          !this.props.singleDateMode ? (
            <div style={{ marginLeft: "1.125em", width: "12em" }}>
              <InputLabel
                htmlFor="start-date"
                shrink={true}
                style={{ marginBottom: "0px" }}
              >
                Start Date
              </InputLabel>
              <div className={joinClasses([styles.DateRangeElement, styles.DayPickerInputBordered])}>
                <DayPickerInput
                  value={startDate}
                  formatDate={this._formatDate}
                  placeholder={"Start Date"}
                  dayPickerProps={{
                    selectedDays: { from: startDate, to: endDate },
                    disabledDays: { after: pickerMaxDate, before: pickerMinDate },
                    modifiers,
                    onDayClick: () => {
                      if (this.endDate) {
                        this.endDate.getInput().focus();
                      }
                    },
                    numberOfMonths: 2,
                  }}
                  onDayChange={this._onStartDateChange}
                  classNames={{ overlay: styles.StartDayPickerOverlay, container: "", overlayWrapper: styles.DayPickerOverlayWrapper }}

                />
                <DateRangeIcon />
              </div>
            </div>
          ) : (
              <div />
            )
        )}
        <div style={{ marginLeft: "1.125em", width: "12em" }}>
          {
            this.props.singleDateMode ?
              (<InputLabel htmlFor="end-date" shrink={true} style={{ marginBottom: "0px" }}>Search Date</InputLabel>) :
              (<InputLabel htmlFor="end-date" shrink={true} style={{ marginBottom: "0px" }}>End Date</InputLabel>)
          }
          <div className={joinClasses([styles.DateRangeElement, styles.DayPickerInputBordered])}>
            <DayPickerInput
              ref={el => (this.endDate = el)}
              value={endDate}
              formatDate={this._formatDate}
              placeholder={"End Date"}
              dayPickerProps={{
                selectedDays: { from: startDate, to: endDate },
                disabledDays: { after: (this.props.singleDateMode ? pickerMaxDate : (endLimiterDate < pickerMaxDate ? endLimiterDate : pickerMaxDate)), before: (this.props.singleDateMode ? pickerMinDate : startDate) },
                modifiers,
                numberOfMonths: 2,
                month: this.state.displayMonth,
              }}
              onDayChange={this._onEndDateChange}
              hideOnDayClick={false}
              classNames={{ overlay: styles.EndDayPickerOverlay, container: "", overlayWrapper: styles.DayPickerOverlayWrapper }}
            />
            <DateRangeIcon />
          </div>
        </div>
      </div>
    );
  }
}
