import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  TableRow
} from "$Imports/MaterialUIComponents";

import {
  DataTable,
  DataTablePager,
  IDataTableColumn,
  DateFormatter
} from "$Components/Common";

import {
  DriveCamSubmissionResultViewModelStatusEnum,
  SmartDriveExportError
} from "$Generated/api";

import {
  IPagerState,
  calcPagedState
} from "$State/PagerPagingState";

interface IMultipleSmartDriveSummaryGridProps {
  pager: IPagerState;
  data: SmartDriveExportError[];
  onPagerStateChange?: (pagerState: IPagerState) => void;
  submissionStatus: DriveCamSubmissionResultViewModelStatusEnum;
}

const styles: {
  columnHeader: string;
} = require("../error-report-view//SubmissionErrorReport.scss");

export class MultipleSmartDriveErrorSummaryGrid extends React.PureComponent<IMultipleSmartDriveSummaryGridProps> {

  private readonly columns: IDataTableColumn<SmartDriveExportError>[] = [
    {
      columnName: "vehicleSN",
      columnFieldData: (x) => x.vehicleSN ?? "",
      sortMethod: (x) => (x.vehicleSN ?? "").toLowerCase(),
      headerValue: "Vehicle SN",
      headerProps: {}
    },
    {
      columnName: "employeeId",
      columnFieldData: (x) => x.employeeId ?? "",
      sortMethod: (x) => (x.employeeId ?? "").toLowerCase(),
      headerValue: "Employee Id",
      headerProps: {}
    },

    {
      columnName: "startDateTime",
      columnFieldData: (x) => x.startDateTime ? (<DateFormatter value={x.startDateTime} />) : "",
      sortMethod: (x) => x.startDateTime,
      headerValue: "Start Date",
      headerProps: {}
    },
    {
      columnName: "endDateTime",
      columnFieldData: (x) => x.endDateTime ? (<DateFormatter value={x.endDateTime} />) : "",
      sortMethod: (x) => x.endDateTime,
      headerValue: "End Date",
      headerProps: {}
    },
    {
      columnName: "responseCode",
      columnFieldData: (x) => x.responseCode ?? "",
      sortMethod: (x) => (x.responseCode ?? "").toLowerCase(),
      headerValue: "Response Code",
      headerProps: {}
    },
    {
      columnName: "responseMessage",
      columnFieldData: (x) => x.responseMessage ?? "",
      sortMethod: (x) => (x.responseMessage ?? "").toLowerCase(),
      headerValue: "Response Message",
      headerProps: {}
    }
  ]

  @bind
  private _onPagerStateChanged(page: number, rowsPerPage: number) {
    if (this.props.onPagerStateChange) {
      this.props.onPagerStateChange({
        page,
        rowsPerPage,
      });
    }
  }

  @bind
  private _onChangePage() {
    // Do nothing.  Handled by the component.  Remove once typescript has been updated.
  }

  render() {
    const { data, pager } = this.props;
    const pagedState = calcPagedState(pager);

    return (
      <DataTable
        columns={this.columns}
        data={data}
        skipRows={pagedState.skip}
        takeRows={pagedState.take}
        stickyHeader
        tableFooterComponent={(
          <TableRow>
            <DataTablePager
              count={data.length}
              page={pager.page}
              rowsPerPage={pager.rowsPerPage}
              onPagerStateChange={this._onPagerStateChanged}
              onChangePage={this._onChangePage}
            />
          </TableRow>
        )}
      />
    );
  }
}