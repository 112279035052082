import {
  React,
  bind,
  moment,
  ChangeEvent
} from "$Imports/Imports";

import {
  SearchBox,
  DateRangeFilter,
  IDateRange,
  TenantSelector,
} from "$Components/Common";

import {
  ISearchFilter
} from "$State/VehicleSearchFreezerService";

import {
  TenantSummaryViewModel,
} from "$Generated/api";

import {
  Button,
  InputLabel,
  Select,
  MenuItem,
  FormControl
} from "$Imports/MaterialUIComponents";

const styles: {
  searchBox: string;
  searchContainer: string;
  tenantBox: string;
  searchButton: string;
  searchByControl: string;
  dateRangeFilter: string;
  lowerSearch: string;
} = require("./Filter.scss");

interface IFilterProps {
  filter?: ISearchFilter;
  onFilterChanged: (filter: ISearchFilter, reload: boolean) => void;
  onRunSearch: () => void;
  tenantSummaryData: TenantSummaryViewModel[];
  filterStatusLabel: string;
  errorState: boolean;
}

interface IFilterState {
  selectedSearch: string;
}

const defaultDateRange: IDateRange = {
  endDate: null,
  startDate: null
};

const defaultFilter: ISearchFilter = {
  dateRange: defaultDateRange,
  searchValue: "",
};

export class Filter extends React.PureComponent<IFilterProps, IFilterState> {

  static defaultProps: Partial<IFilterProps> = {
    filter: defaultFilter,
    filterStatusLabel: "",
  };

  public constructor(props: IFilterProps) {
    super(props);
    this.state = { selectedSearch: "vehicleId" };
  }

  @bind
  private _onVehicleIdSearchChange(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>) {
    const notNullFilter = this.props.filter ? this.props.filter : defaultFilter;
    this._raiseFilterChange({
      dateRange: notNullFilter.dateRange,
      searchValue: "",
      vehicleId: event.target.value,
    });
  }

  @bind
  private _onDriverIdSearchChange(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>) {
    const notNullFilter = this.props.filter ? this.props.filter : defaultFilter;
    this._raiseFilterChange({
      dateRange: notNullFilter.dateRange,
      searchValue: "",
      driverId: event.target.value,
    });
  }

  @bind
  private _onDriverLastNameSearchChange(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>) {
    const notNullFilter = this.props.filter ? this.props.filter : defaultFilter;
    this._raiseFilterChange({
      dateRange: notNullFilter.dateRange,
      searchValue: "",
      lastName: event.target.value,
    });
  }

  @bind
  private _onDateRangeChange(date: IDateRange): void {
    const notNullFilter = this.props.filter ? this.props.filter : defaultFilter;

    this._raiseFilterChange({
      dateRange: date,
      searchValue: notNullFilter.searchValue,
      tenantId: notNullFilter.tenantId
    });

    if (notNullFilter.tenantId === undefined || notNullFilter.tenantId === "") {
      return;
    }

    this._runSearch();
  }

  @bind
  private _onClearVehicleId() {
    const notNullFilter = this.props.filter ? this.props.filter : defaultFilter;

    this._raiseFilterChange({
      dateRange: notNullFilter.dateRange,
      searchValue: "",
      tenantId: notNullFilter.tenantId,
      vehicleId: "",
      lastName: notNullFilter.lastName,
      driverId: notNullFilter.driverId
    });
  }

  @bind
  private _onClearDriverId() {
    const notNullFilter = this.props.filter ? this.props.filter : defaultFilter;

    this._raiseFilterChange({
      dateRange: notNullFilter.dateRange,
      searchValue: "",
      tenantId: notNullFilter.tenantId,
      vehicleId: notNullFilter.vehicleId,
      lastName: notNullFilter.lastName,
      driverId: ""
    });
  }

  @bind
  private _onClearDriverLastName() {
    const notNullFilter = this.props.filter ? this.props.filter : defaultFilter;

    this._raiseFilterChange({
      dateRange: notNullFilter.dateRange,
      searchValue: "",
      tenantId: notNullFilter.tenantId,
      vehicleId: notNullFilter.vehicleId,
      lastName: "",
      driverId: notNullFilter.driverId
    });
  }

  @bind
  private _clearAll() {
    const notNullFilter = this.props.filter ? this.props.filter : defaultFilter;

    this._raiseFilterChange({
      dateRange: notNullFilter.dateRange,
      searchValue: "",
      tenantId: notNullFilter.tenantId,
      vehicleId: "",
      lastName: "",
      driverId: ""
    });
  }

  @bind
  private _onTenantSelectionChanged(tenant: TenantSummaryViewModel | undefined) {
    const notNullFilter = this.props.filter ? this.props.filter : defaultFilter;

    this._raiseFilterChange({
      dateRange: notNullFilter.dateRange,
      searchValue: notNullFilter.searchValue,
      tenantId: tenant && tenant.workflowInstanceId ?
        tenant.workflowInstanceId : tenant && tenant.tenantId ?
          tenant.tenantId : undefined,
    });

    this._runSearch();
  }

  private _raiseFilterChange(filter: ISearchFilter) {
    if (this.props.onFilterChanged) {
      this.props.onFilterChanged(filter, false);
    }
  }

  @bind
  private _runSearch() {
    if (this.props.onRunSearch) {
      this.props.onRunSearch();
    }
  }

  @bind
  private _onKeyDown(event: React.KeyboardEvent<HTMLDivElement>): void {
    if (event.keyCode === 13) {
      if (this.props.onRunSearch) {
        this.props.onRunSearch();
      }
    }
  }

  render() {
    const { filter, tenantSummaryData } = this.props;
    const notNullFilter = filter ? filter : defaultFilter;

    let dateRange = notNullFilter.dateRange;

    // Filter out tenants that submit to Lytx Rair (LDM-1991)
    const filteredTenantSummaryData = tenantSummaryData.filter(tenant => tenant.submissionProviderId != "78");

    return (<div
      className={styles.searchContainer}
    >
      <div
        className={styles.tenantBox}
      >
        <div className={styles.searchContainer}>
          <TenantSelector
            value={notNullFilter.tenantId}
            sourceTenantData={filteredTenantSummaryData}
            onTenantChange={this._onTenantSelectionChanged}
            errorState={this.props.errorState}
          />
          <div className={styles.dateRangeFilter}>
            <DateRangeFilter
              value={dateRange}
              onChange={this._onDateRangeChange}
              maxDayRange={30}
              minDate={moment().subtract(90, "days")}
              maxDate={moment()}
            />
          </div>
        </div>
        <div className={styles.lowerSearch}>
          <div className={styles.searchBox}>
            <SearchBox
              value={notNullFilter.vehicleId}
              onChange={this._onVehicleIdSearchChange}
              label={"Vehicle Id"}
              onClearClick={this._onClearVehicleId}
              onKeyDown={this._onKeyDown}
              error={this.props.errorState && !notNullFilter.vehicleId}
            />
          </div>
          <div className={styles.searchBox}>
            <SearchBox
              value={notNullFilter.driverId}
              onChange={this._onDriverIdSearchChange}
              label={"Driver Id"}
              onClearClick={this._onClearDriverId}
              onKeyDown={this._onKeyDown}
              error={this.props.errorState && !notNullFilter.driverId}
            />
          </div>
          <div className={styles.searchBox}>
            <SearchBox
              value={notNullFilter.lastName}
              onChange={this._onDriverLastNameSearchChange}
              label={"Driver Last Name"}
              onClearClick={this._onClearDriverLastName}
              onKeyDown={this._onKeyDown}
              error={this.props.errorState && !notNullFilter.lastName}
            />
          </div>
          <Button size="small"
            className={styles.searchButton}
            variant="contained"
            color="primary"
            onClick={this._clearAll}>
            Clear
          </Button>
        </div>
      </div>
    </div>
    );
  }
}
