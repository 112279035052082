import {
  React,
  bind,
  numeral,
  _
} from "$Imports/Imports";

import {
  DataTable,
  DataTablePager,
  IDataTableColumn,
  directionType,
  DateFormatter,
  JobStatusLegend
} from "$Components/Common";

import {
  TableCell,
  TableRow,
  Divider
} from "$Imports/MaterialUIComponents";

import {
  JobStatusIndicator
} from "$Components/Common/JobStatusIndicator/JobStatusIndicator";

import {
  TenantNameLink
} from "./TenantNameLink";

import {
  JobSummaryViewModel
} from "$Generated/api";

import {
  IPagerState,
  calcPagedState
} from "$State/PagerPagingState";

import {
  ISortState
} from "$State/SortState";

interface IJobSummaryGridProps {
  data: JobSummaryViewModel[];
  pager: IPagerState;
  sortState: ISortState;
  onPagerStateChange?: (pagerState: IPagerState) => void;
  onSortChange?: (sortState: ISortState) => void;
  onTenantClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: JobSummaryViewModel) => void;
}

export class JobSummaryGrid extends React.PureComponent<IJobSummaryGridProps> {

  private readonly columns: IDataTableColumn<JobSummaryViewModel>[] = [
    {
      columnName: "status",
      cellProps: {
        style: {
          textAlign: "center",
        }
      },
      headerProps: {
        style: {
          textAlign: "center",
        }
      },
      headerValue: "Status",
      columnFieldData: (d) => (<JobStatusIndicator
        jobStatus={d.jobStatus}
        jobStartDate={d.startDate}
      />)
    },
    {
      columnName: "tenant-name",
      columnFieldData: (d) => {
        return (
          <TenantNameLink
            data={d}
            onClick={this._onTenantClick}
          />
        );
      },
      sortMethod: (d) => (d.tenantFriendlyName ? d.tenantFriendlyName : "").toLowerCase(),
      headerValue: "Tenant",
    },
    {
      columnName: "adapter-name",
      columnFieldData: (d) => d.adapters ? d.adapters.map((a) => a.friendlyName).join(', ') : "",
      sortMethod: (d) => d.adapters ? d.adapters.map((a) => a.friendlyName).join(', ').toLowerCase() : "",
      headerValue: "Adapter"
    },
    {
      columnName: "start-date",
      columnFieldData: (d) => d.startDate ? (<DateFormatter value={d.startDate} />) : null,
      sortMethod: (d) => d.startDate,
      headerValue: "Start Date"
    },
    {
      columnName: "end-date",
      columnFieldData: (d) => d.endDate ? (<DateFormatter value={d.endDate} />) : null,
      sortMethod: (d) => d.endDate,
      headerValue: "End Date"
    },
    {
      columnName: "total-submission",
      columnFieldData: (d) => numeral(d.itemsSubmitted).format("0,000"),
      sortMethod: (d) => d.itemsSubmitted,
      headerValue: "Total Submission"
    },
    {
      columnName: "success-rate",
      columnFieldData: (d) => numeral((d.itemsSuccessful ? d.itemsSuccessful : 0) / (d.itemsSubmitted ? d.itemsSubmitted : 1)).format("0.00%"),
      sortMethod: (d) => (d.itemsSuccessful ? d.itemsSuccessful : 0) / (d.itemsSubmitted ? d.itemsSubmitted : 1),
      headerValue: "Success Rate"
    }
  ];

  @bind
  private _onTenantClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: JobSummaryViewModel) {
    if (this.props.onTenantClick) {
      this.props.onTenantClick(event, data);
    }
  }

  @bind
  private _onPagerStateChanged(page: number, rowsPerPage: number) {
    if (this.props.onPagerStateChange) {
      this.props.onPagerStateChange({
        page,
        rowsPerPage,
      });
    }
  }

  @bind
  private _onSortChange(event: React.MouseEvent<HTMLElement, MouseEvent>, columnName: string | undefined, sortDirection: directionType) {
    if (this.props.onSortChange) {
      this.props.onSortChange({
        sortColumnName: columnName,
        sortDirection
      });
    }
  }

  @bind
  private _onChangePage() {
    // Do nothing.  Handled by the component.  Remove once typescript has been updated.
  }

  render() {
    const { data, pager, sortState } = this.props;
    const pagedState = calcPagedState(pager);

    return (
      <>
        {data.length > 0 ?
          <>
            <table style={{ marginLeft: "auto" }}>
              <tr>
                <DataTablePager
                  style={{ borderBottom: "none" }}
                  onChangePage={this._onChangePage}
                  count={data.length}
                  page={pager.page}
                  rowsPerPage={pager.rowsPerPage}
                  onPagerStateChange={this._onPagerStateChanged}
                />
              </tr>
            </table>
            <Divider />
          </> : null
        }
        <DataTable
          columns={this.columns}
          defaultSortColumnName={sortState.sortColumnName}
          defaultSortDirection={sortState.sortDirection}
          data={data}
          skipRows={pagedState.skip}
          takeRows={pagedState.take}
          stickyHeader
          onSortChange={this._onSortChange}
          tableFooterComponent={(
            <TableRow>
              <TableCell
                colSpan={3}
              >
                <JobStatusLegend />
              </TableCell>
              <DataTablePager
                count={data.length}
                page={pager.page}
                rowsPerPage={pager.rowsPerPage}
                onPagerStateChange={this._onPagerStateChanged}
                onChangePage={this._onChangePage}
              />
            </TableRow>
          )}
        />
      </>
    );
  }
}
