import {
  React,
  _,
  bind,
  moment
} from "$Imports/Imports";

import {
  IPagerState,
  calcPagedState
} from "$State/PagerPagingState";

import {
  DataTable,
  DataTablePager,
  IDataTableColumn,
  directionType,
  DateFormatter
} from "$Components/Common";

import {
  TableRow
} from "$Imports/MaterialUIComponents";

import {
  Assignment
} from "$Generated/api";

import {
  ISortState
} from "$State/SortState";

import {
  ISecurityContext,
} from "$Providers/AuthenticationProvider";

interface IVehicleAssignmentGridProps {
  securityContext: ISecurityContext;
  pagerState: IPagerState;
  data: Assignment[];
  sortState: ISortState;
  onPagerStateChange?: (pagerState: IPagerState) => void;
  onSortChange?: (sortState: ISortState) => void;
}

const styles: {
  columnHeader: string;
} = require("./AssignmentRecordsSearch.scss");

export class AssignmentGrid extends React.PureComponent<IVehicleAssignmentGridProps> {

  private getColumns(securityContext: ISecurityContext): IDataTableColumn<Assignment>[] {
    var table: IDataTableColumn<Assignment>[] = [
      {
        columnName: "vehicle-id",
        columnFieldData: "vehicleId",
        sortMethod: (d) => d.vehicleId ? d.vehicleId.toLowerCase() : "",
        headerValue: "Vehicle Id",
        headerProps: { className: styles.columnHeader }
      },
      {
        columnName: "driver-id",
        columnFieldData: "driverId",
        sortMethod: (d) => d.driverId ? d.driverId.toLowerCase() : "",
        headerValue: "Driver Id",
        headerProps: { className: styles.columnHeader }
      },
      {
        columnName: "driver-first-name",
        columnFieldData: "driverFirstname",
        sortMethod: (d) => d.driverFirstname ? d.driverFirstname.toLowerCase() : "",
        headerValue: "First Name",
        headerProps: { className: styles.columnHeader }
      },
      {
        columnName: "driver-last-name",
        columnFieldData: "driverLastname",
        sortMethod: (d) => d.driverLastname ? d.driverLastname.toLowerCase() : "",
        headerValue: "Last Name",
        headerProps: { className: styles.columnHeader }
      },
      {
        columnName: "start-time",
        columnFieldData: (d) => (<DateFormatter value={d.startTime} />),
        sortMethod: (d) => d.startTime,
        headerValue: "Start Time",
        headerProps: { className: styles.columnHeader }
      },
      {
        columnName: "end-time",
        columnFieldData: (d) => (!moment(d.endTime).isSame("0001-01-01T00:00:00Z") ? <DateFormatter value={d.endTime} /> : ""),
        sortMethod: (d) => d.endTime,
        headerValue: "End Time",
        headerProps: { className: styles.columnHeader }
      }
    ];

    if (securityContext.IsAdminUser) {
      table.push(
        {
          columnName: "tenant-id",
          columnFieldData: "tenantId",
          headerValue: "Tenant Id",
          headerProps: { className: styles.columnHeader }
        }
      );
    };

    return table;
  };

  @bind
  private _onPagerStateChanged(page: number, rowsPerPage: number) {
    if (this.props.onPagerStateChange) {
      this.props.onPagerStateChange({
        page,
        rowsPerPage,
      });
    }
  }


  @bind
  private _onSortChange(event: React.MouseEvent<HTMLElement, MouseEvent>, columnName: string | undefined, sortDirection: directionType) {
    if (this.props.onSortChange) {
      this.props.onSortChange({
        sortColumnName: columnName,
        sortDirection
      });
    }
  }

  @bind
  private _onChangePage() {
    // Do nothing.  Handled by the component.  Remove once typescript has been updated.
  }

  render() {
    const { securityContext, data, pagerState, sortState } = this.props;
    const pagedState = calcPagedState(pagerState);

    return (
      <DataTable
        columns={this.getColumns(securityContext)}
        data={data}
        skipRows={pagedState.skip}
        takeRows={pagedState.take}
        onSortChange={this._onSortChange}
        defaultSortColumnName={sortState.sortColumnName}
        defaultSortDirection={sortState.sortDirection}
        stickyHeader
        tableFooterComponent={(
          <TableRow>
            <DataTablePager
              count={data.length}
              onChangePage={this._onChangePage}
              page={pagerState.page}
              rowsPerPage={pagerState.rowsPerPage}
              onPagerStateChange={this._onPagerStateChanged}
            />
          </TableRow>
        )}
      />
    );
  }
}
