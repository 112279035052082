import {
  React,
  _,
  bind,
} from "$Imports/Imports";

import {
  Container
} from "./Container";

import {
  AjaxActionIndicator,
  PageHeader
} from "$Components/Common";

import {
  Paper,
  Snackbar
} from "$Imports/MaterialUIComponents";

import {
  VehicleSearchOptions
} from "./VehicleSearchOptions";

import {
  DutyStatusSearch
} from "./DutyStatusSearch";

import {
  ISearchFilter,
  VehicleSearchService,
  IVehicleSearchServiceInjectedProps,
  viewType
} from "$State/VehicleSearchFreezerService";

import {
  ITenantSummaryServiceInjectedProps,
  TenantSummaryService
} from "$State/TenantSummaryFreezerService";

import {
  IPagerState
} from "$State/PagerPagingState";

import {
  AssignmentRecordsSearch
} from "./AssignmentRecordsSearch";

import {
  ISortState
} from "$State/SortState";


const styles: {
  paperStyle: string;
  searchPanel: string;
  searchPaperStyle: string;
} = require("./VehicleSearch.scss");

interface IVehicleSearchBaseProps {

}

interface IVehicleSearchState {

}

type IVehicleSearchProps = IVehicleSearchBaseProps & IVehicleSearchServiceInjectedProps & ITenantSummaryServiceInjectedProps;

class _VehicleSearch extends React.Component<IVehicleSearchProps, IVehicleSearchState> {

  componentWillMount() {
    this.props.tenantSummaryService.fetchTenants();
  }

  @bind
  private _onFilterChange(filter: ISearchFilter, reload: boolean = true) {
    const { view } = this.props.vehicleSearchService.getState();

    if (view === "dutyStatus") {
      this.props.vehicleSearchService.setDutyStatusFilter(filter, reload);
    }

    if (view === "assignmentRecords") {
      this.props.vehicleSearchService.setAssignmentFilter(filter, reload);
    }
  }

  @bind
  private _onRunSearch() {
    const { view } = this.props.vehicleSearchService.getState();

    if (view === "dutyStatus") {
      this.props.vehicleSearchService.fetchDutyStatus(true);
    }
    if (view === "assignmentRecords") {
      this.props.vehicleSearchService.fetchVehicleAssignments(true);
    }
  }

  @bind
  private _onPagerStateChange(pagerState: IPagerState) {
    const { view } = this.props.vehicleSearchService.getState();

    if (view === "dutyStatus") {
      this.props.vehicleSearchService.setDutyStatusPagerState(pagerState);
    }
    if (view === "assignmentRecords") {
      this.props.vehicleSearchService.setAssignmentPagerState(pagerState);
    }
  }

  @bind
  private _onSortStateChange(sortState: ISortState) {
    const { view } = this.props.vehicleSearchService.getState();

    if (view === "dutyStatus") {
      this.props.vehicleSearchService.setDutyStatusSortState(sortState);
    }
    if (view === "assignmentRecords") {
      this.props.vehicleSearchService.setAssignmentSortState(sortState);
    }
  }

  @bind
  private _onSelectedViewChange(event: React.ChangeEvent<{}>, view: viewType): void {
    this.props.vehicleSearchService.setView(view);

  }

  render() {
    const {
      dutyStatusFilter,
      assignmentFilter,
      view,
      dutyStatusResults,
      dutyStatusPager,
      dutyStatusSort,
      vehicleAssignmentResults,
      assignmentPager,
      assignmentSort,
      errorState,
      hasSearched
    } = this.props.vehicleSearchService.getState();

    const {
      tenantResults
    } = this.props.tenantSummaryService.getState();

    const dutyStatusData = dutyStatusResults.data && dutyStatusResults.data.data ? dutyStatusResults.data.data : [];

    const vehicleAssignmentData = vehicleAssignmentResults.data && vehicleAssignmentResults.data.data ? vehicleAssignmentResults.data.data : [];

    const tenantSummaryData = tenantResults.data && tenantResults.data.data ? tenantResults.data.data : [];

    return (
      <Container>
        <PageHeader title="Vehicle Search" />
        <Paper className={styles.paperStyle}>
          <VehicleSearchOptions
            selectedView={view}
            onViewChange={this._onSelectedViewChange}
          />
        </Paper>
        <Paper className={styles.paperStyle}>
          {
            view === "dutyStatus" ? (
              <>
                <AjaxActionIndicator
                  state={[dutyStatusResults, tenantResults]}
                />
                <DutyStatusSearch
                  sortState={dutyStatusSort}
                  onSortChange={this._onSortStateChange}
                  dutyStatusData={dutyStatusData}
                  pagerState={dutyStatusPager}
                  onFilterChange={this._onFilterChange}
                  onRunSearch={this._onRunSearch}
                  filter={dutyStatusFilter}
                  tenantSummaryData={tenantSummaryData}
                  onPagerStateChange={this._onPagerStateChange}
                  errorState={errorState}
                  hasSearched={hasSearched}
                />

              </>
            ) : null
          }
          {
            view === "assignmentRecords" ? (
              <>
                <AjaxActionIndicator
                  state={[vehicleAssignmentResults, tenantResults]}
                />
                <AssignmentRecordsSearch
                  assignmentRecordsData={vehicleAssignmentData}
                  filter={assignmentFilter}
                  tenantSummaryData={tenantSummaryData}
                  onFilterChange={this._onFilterChange}
                  onPagerStateChange={this._onPagerStateChange}
                  onSortChange={this._onSortStateChange}
                  pagerState={assignmentPager}
                  sortState={assignmentSort}
                  onRunSearch={this._onRunSearch}
                  errorState={errorState}
                  hasSearched={hasSearched}
                />
              </>
            ) : null
          }
        </Paper>
        <Snackbar
          open={errorState}
          message={"Search Criteria Required"}
          autoHideDuration={5000}
          anchorOrigin={{
            horizontal: "center",
            vertical: "bottom"
          }}
        />
      </Container>
    );
  }
}

export const VehicleSearch = TenantSummaryService.inject(
  VehicleSearchService.inject(
    _VehicleSearch
  )
);
