import {
  React,
  bind,
  moment,
  _
} from "$Imports/Imports";

import {
  TableRow
} from "$Imports/MaterialUIComponents";

import {
  DataTable,
  DataTablePager,
  IDataTableColumn,
  DateFormatter
} from "$Components/Common";

import {
  SubmissionErrorDetailViewModel,
  DriveCamSubmissionResultViewModelStatusEnum
} from "$Generated/api";

import {
  IPagerState,
  calcPagedState
} from "$State/PagerPagingState";

interface ISubmissionErrorSummaryGridProps {
  pager: IPagerState;
  data: SubmissionErrorDetailViewModel[];
  onPagerStateChange?: (pagerState: IPagerState) => void;
  submissionStatus: DriveCamSubmissionResultViewModelStatusEnum;
}

const styles: {
  columnHeader: string;
} = require("./SubmissionErrorReport.scss");

export class SubmissionErrorSummaryGrid extends React.PureComponent<ISubmissionErrorSummaryGridProps> {
  private readonly columns: IDataTableColumn<SubmissionErrorDetailViewModel>[] = [
    {
      columnName: "vehicle-id",
      columnFieldData: (d) => d.vehicleId ? d.vehicleId : "",
      sortMethod: (d) => (d.vehicleId ? d.vehicleId : "").toLowerCase(),
      headerValue: "Vehicle Id",
      headerProps: { className: styles.columnHeader }
    },
    {
      columnName: "driver-id",
      columnFieldData: (d) => d.driverId ? d.driverId : "",
      sortMethod: (d) => (d.driverId ? d.driverId : "").toLowerCase(),
      headerValue: "Driver Id",
      headerProps: { className: styles.columnHeader }
    },
    {
      columnName: "first-name",
      columnFieldData: (d) => d.firstName ? d.firstName : "",
      sortMethod: (d) => (d.firstName ? d.firstName : "").toLowerCase(),
      headerValue: "First Name",
      headerProps: { className: styles.columnHeader }
    },
    {
      columnName: "last-name",
      columnFieldData: (d) => d.lastName ? d.lastName : "",
      sortMethod: (d) => (d.lastName ? d.lastName : "").toLowerCase(),
      headerValue: "Last Name",
      headerProps: { className: styles.columnHeader }
    },
    {
      columnName: "start-date",
      columnFieldData: (d) => d.startDate ? (<DateFormatter value={d.startDate} />) : null,
      sortMethod: (d) => d.startDate,
      headerValue: "Start Date",
      headerProps: { className: styles.columnHeader }
    },
    {
      columnName: "end-date",
      columnFieldData: (d) => d.endDate ? (<DateFormatter value={d.endDate} />) : null,
      sortMethod: (d) => d.endDate,
      headerValue: "End Date",
      headerProps: { className: styles.columnHeader }
    },
    {
      columnName: "response-code",
      columnFieldData: (d) => d.responseCode && d.responseCode != "" ? d.responseCode : d.additionalInformation ? d.additionalInformation["ResponseCode"] : "",
      sortMethod: (d) => (d.responseCode && d.responseCode != "" ? d.responseCode : d.additionalInformation ? d.additionalInformation["ResponseCode"] : "").toLowerCase(),
      headerValue: "Response Code",
      headerProps: { className: styles.columnHeader }
    },
    {
      columnName: "endpointId",
      columnFieldData: (d) => d.endpointId && d.endpointId !== "" ? d.endpointId : d.additionalInformation ? d.additionalInformation["EndpointId"] : "",
      sortMethod: (d) => (d.endpointId && d.endpointId !== "" ? d.endpointId : d.additionalInformation ? d.additionalInformation["EndpointId"] : "").toLowerCase(),
      headerValue: "Endpoint Id",
      headerProps: { className: styles.columnHeader }
    },
    {
      columnName: "submissionId",
      columnFieldData: (d) => d.submissionId && d.submissionId != 0 ? d.submissionId : d.additionalInformation ? d.additionalInformation["SubmissionId"] : "",
      sortMethod: (d) => d.submissionId && d.submissionId != 0 ? d.submissionId : d.additionalInformation ? d.additionalInformation["SubmissionId"] : "",
      headerValue: "Job Submission Id",
      headerProps: { className: styles.columnHeader }
    }
  ];


  @bind
  private _onPagerStateChanged(page: number, rowsPerPage: number) {
    if (this.props.onPagerStateChange) {
      this.props.onPagerStateChange({
        page,
        rowsPerPage,
      });
    }
  }

  @bind
  private _onChangePage() {
    // Do nothing.  Handled by the component.  Remove once typescript has been updated.
  }

  render() {
    const { data, pager } = this.props;
    const pagedState = calcPagedState(pager);

    return (
      <DataTable
        columns={this.columns}
        data={data}
        skipRows={pagedState.skip}
        takeRows={pagedState.take}
        stickyHeader
        tableFooterComponent={(
          <TableRow>
            <DataTablePager
              count={data.length}
              page={pager.page}
              rowsPerPage={pager.rowsPerPage}
              onPagerStateChange={this._onPagerStateChanged}
              onChangePage={this._onChangePage}
            />
          </TableRow>
        )}
      />
    );
  }
}